import * as React from 'react'
import { Link } from 'gatsby'
import IndexLayout from '../layouts'

export default function Yoga() {
  return (
    <IndexLayout>
      <h1 className="sectionTitle">Yoga</h1>
      <p>Das Wort, das aus der Seele kommt, das setzt sich ganz bestimmt ins Herz dschelal ed-din rumi</p>
      <p>Yoga bedeutet für mich:</p>
      <ul>
        <li>Bei mir sein</li>
        <li>Meinen, mir innewohnenden Rhythmus (Herzklopfen, Atem) wahrnehmen</li>
        <li>Mit fließendem Atem meinen Körper spüren</li>
        <li>Sanft meine Begrenzungen (Ängste, Verspannungen) öffnen</li>
        <li>Kraftvoll in Ruhe und Mitgefühl handeln</li>
      </ul>
      <p>Wie ist mein Unterricht:</p>
      <ul>
        <li>Ich nenne ihn „Yoga für den Alltag"</li>
        <li>Wir achten auf den Atem, verwurzeln uns gedanklich und werden ruhig</li>
        <li>Es folgen Dehnungsübungen immer im Einklang mit deinem Atemrhythmus</li>
        <li>
          Wir praktizieren verschiedene Körperübungen und Körperstellungen (Asanas), abgestimmt auf dich und die Gruppe und immer im
          Einklang mit deinem Atemrhythmus
        </li>
        <li>
          Jede Stunde endet mit einer Entspannungsreise. Du steigst mittels Vorstellung in deine Seelenlandschaft ein, erhältst Zugang zu
          deinem Unbewussten und begegnest dir in Ruhe und Gelassenheit
        </li>
      </ul>

      <p>Meine Tipps, um Yoga in den Alltag zu integrieren:</p>
      <ul>
        <li>Nähre sie Sehnsucht, dich kennen zu lernen</li>
        <li>Atme durch, bevor du handelst und nimm deine „Probleme“ nicht zu ernst</li>
        <li>Handle bewusst und vertraue deinem Gespür</li>
        <li>Lache und treibe Unfug, auch wenn andere über dich lachen</li>
        <li>Dein Körper hat dann, wie von selbst, das Bedürfnis beweglich zu sein</li>
      </ul>

      <p>Kurse / Workshops</p>
      <ul>
        <li>
          derzeit unterrichte ich bei verschiedenen Institutionen und gebe an Schulen Schnupperkurse über das Tiroler Schulsportservice
        </li>
        <li>
          Das Kursprogramm findest du unter{' '}
          <Link to="/aktuelles" onClick={() => this.props.onLinkClicked('Aktuelles')} style={{ textDecoration: 'underline' }}>
            Aktuelles
          </Link>
        </li>
      </ul>
    </IndexLayout>
  )
}
